import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/release-notes.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ThankYou = makeShortcode("ThankYou");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p {...{
      "className": "date"
    }}>{`25th February 2020`}</p>


    <h2 {...{
      "id": "-new-features",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-new-features",
        "aria-label": " new features permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🌟 New features`}</h2>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`RequestContextExporter`}</inlineCode>{` and `}<inlineCode parentName="li">{`RequestContextExportingAppender`}</inlineCode>{` for Logback can now export the current request ID into MDC. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2511"
        }}>{`#2511`}</a>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-xml"
          }}>{`<?xml version="1.0" encoding="UTF-8"?>
<configuration>
  <appender name="CONSOLE" class="ch.qos.logback.core.ConsoleAppender">
    <encoder>
      <pattern>%d{HH:mm:ss.SSS} %X{req.id} %msg%n</pattern>
    </encoder>
  </appender>
  <appender name="RCEA" class="com.linecorp.armeria.common.logback.RequestContextExportingAppender">
    <appender-ref ref="CONSOLE" />
    <export>req.id</export>
  </appender>
</configuration>
`}</code></pre>
      </li>
      <li parentName="ul">{`Client-side options API (`}<inlineCode parentName="li">{`ClientOption`}</inlineCode>{` and `}<inlineCode parentName="li">{`ClientFactoryOption`}</inlineCode>{`) has been revamped. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2523"
        }}>{`#2523`}</a>
        <ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`ClientOptions.get()`}</inlineCode>{` and `}<inlineCode parentName="li">{`ClientFactoryOptions.get()`}</inlineCode>{` never throw an exception. A default value is returned for an unspecified option. Therefore, other getter methods such as `}<inlineCode parentName="li">{`getOrElse()`}</inlineCode>{` have been removed.`}</li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "-improvements",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-improvements",
        "aria-label": " improvements permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`📈 Improvements`}</h2>
    <ul>
      <li parentName="ul">{`You'll now get a `}<inlineCode parentName="li">{`ClosedStreamException`}</inlineCode>{` instead of `}<inlineCode parentName="li">{`Http2Exception`}</inlineCode>{` when your HTTP/2 connection was closed due to an `}<inlineCode parentName="li">{`RST_STREAM`}</inlineCode>{` frame. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2508"
        }}>{`#2508`}</a></li>
    </ul>
    <h2 {...{
      "id": "️-bug-fixes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-bug-fixes",
        "aria-label": "️ bug fixes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🛠️ Bug fixes`}</h2>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`*ClientBuilder.options(ClientOptions)`}</inlineCode>{` now overrides only the specified options. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2516"
        }}>{`#2516`}</a>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`ClientFactory factory = ClientFactory.insecure();
ClientOptions options = ClientOptions.builder()
                                     .responseTimeMillis(5000)
                                     .build();
WebClient client = WebClient.builder("http://foo.com")
                            .factory(factory)
                            .options(options)
                            .build();
// This assertion does not fail anymore.
assert client.options().factory() == ClientFactory.insecure();
`}</code></pre>
      </li>
      <li parentName="ul">{`Fixed a bug where `}<inlineCode parentName="li">{`DocService`}</inlineCode>{` throws the `}<inlineCode parentName="li">{`java.lang.IllegalStateException: zip file closed`}</inlineCode>{` exception while starting up. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2518"
        }}>{`#2518`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2519"
        }}>{`#2519`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`MetricCollectingClient`}</inlineCode>{` does not count the request that failed initially due to a `}<inlineCode parentName="li">{`connection refused`}</inlineCode>{` error and then succeeded after a retry as failure anymore. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2517"
        }}>{`#2517`}</a></li>
      <li parentName="ul">{`Fixed a bug where `}<inlineCode parentName="li">{`LoggingClient`}</inlineCode>{` and `}<inlineCode parentName="li">{`LoggingSerivce`}</inlineCode>{` does not push the current context when logging. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2528"
        }}>{`#2528`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`RequestContextExporter`}</inlineCode>{` and `}<inlineCode parentName="li">{`RequestContextExportingAppender`}</inlineCode>{` can now export a single custom attribute into multiple MDC properties. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2521"
        }}>{`#2521`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`RequestContextExporter`}</inlineCode>{` and `}<inlineCode parentName="li">{`RequestContextExportingAppender`}</inlineCode>{` does not export outdated custom attributes anymore. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2520"
        }}>{`#2520`}</a></li>
      <li parentName="ul">{`Renamed incorrect meter ID `}<inlineCode parentName="li">{`armeria.server.pendingResponses`}</inlineCode>{` into `}<inlineCode parentName="li">{`armeria.server.pending.responses`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2506"
        }}>{`#2506`}</a></li>
      <li parentName="ul">{`The `}<inlineCode parentName="li">{`Date`}</inlineCode>{` header is now encoded properly even when `}<inlineCode parentName="li">{`System.nanoTime()`}</inlineCode>{` returns a negative value. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2530"
        }}>{`#2530`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`GrpcStatus.fromThrowable()`}</inlineCode>{` now treats `}<inlineCode parentName="li">{`ContentTooLargeException`}</inlineCode>{` as `}<inlineCode parentName="li">{`RESOURCE_EXHAUSTED`}</inlineCode>{` rather than `}<inlineCode parentName="li">{`INTERNAL_ERROR`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2523"
        }}>{`#2523`}</a></li>
    </ul>
    <h2 {...{
      "id": "️-breaking-changes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-breaking-changes",
        "aria-label": "️ breaking changes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`☢️ Breaking changes`}</h2>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`ClientOptions.getOrElse()`}</inlineCode>{` and `}<inlineCode parentName="li">{`getOrNull()`}</inlineCode>{` have been removed. `}<inlineCode parentName="li">{`get()`}</inlineCode>{` always returns a non-null value now. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2523"
        }}>{`#2523`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`ClientOptions.asMap()`}</inlineCode>{` and `}<inlineCode parentName="li">{`ClientFactoryOptions.asMap()`}</inlineCode>{` now return only the options overridden by a user. You can get the `}<inlineCode parentName="li">{`Set`}</inlineCode>{` of available options using `}<inlineCode parentName="li">{`ClientOptions.allOptions()`}</inlineCode>{` or `}<inlineCode parentName="li">{`ClientFactoryOptions.allOptions()`}</inlineCode>{`: `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2516"
        }}>{`#2516`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2523"
        }}>{`#2523`}</a>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`ClientOptions myOptions = ...;
Map<ClientOption<?>, Object> map = new IdentityHashMap<>();
for (ClientOption o : ClientOptions.allOptions()) {
    map.put(myOptions.get(o));
}
`}</code></pre>
      </li>
      <li parentName="ul"><inlineCode parentName="li">{`ClientOptions.valueOf()`}</inlineCode>{` and `}<inlineCode parentName="li">{`ClientFactoryOptions.valueOf()`}</inlineCode>{` have been split into two methods: `}<inlineCode parentName="li">{`define()`}</inlineCode>{` and `}<inlineCode parentName="li">{`of()`}</inlineCode>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2523"
        }}>{`#2523`}</a>{`.`}
        <ul parentName="li">
          <li parentName="ul">{`Use `}<inlineCode parentName="li">{`of()`}</inlineCode>{` if you're getting an existing option.`}</li>
          <li parentName="ul">{`Use `}<inlineCode parentName="li">{`define()`}</inlineCode>{` if you're defining a new option.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`You might need to adjust your monitoring system if you were watching `}<inlineCode parentName="li">{`armeria.server.pendingResponses`}</inlineCode>{`, which has been renamed to `}<inlineCode parentName="li">{`armeria.server.pending.responses`}</inlineCode>{` in this release. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2506"
        }}>{`#2506`}</a></li>
    </ul>
    <h2 {...{
      "id": "-dependencies",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-dependencies",
        "aria-label": " dependencies permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`⛓ Dependencies`}</h2>
    <ul>
      <li parentName="ul">{`Dropwizard 1.3.18 → 1.3.19`}</li>
      <li parentName="ul">{`Dropwizard Metrics 4.1.2 → 4.1.3`}</li>
      <li parentName="ul">{`java-jwt 3.9.0 → 3.10.0`}</li>
      <li parentName="ul">{`RxJava 2.2.17 → 2.2.18`}</li>
      <li parentName="ul">{`Shaded dependencies`}
        <ul parentName="li">
          <li parentName="ul">{`Reflections 0.9.12 → 0.9.11 (Downgrade)`}</li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "-thank-you",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-thank-you",
        "aria-label": " thank you permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🙇 Thank you`}</h2>
    <ThankYou usernames={['andrewoma', 'andrey-tpt', 'anuraaga', 'Boozilla', 'ikhoon', 'jrhee17', 'JunoJunho', 'minwoox', 'trustin']} mdxType="ThankYou" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      